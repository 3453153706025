<template>
  <modal ref="addContractModal" :visible="isAddModalOpen" :close-action="'NULL'" 
  :class="[ typeContract === 'amendment' ? 'modal--addContract_amendment' : null]">
    <button
      :class="['btn', 'btn-secondary', 'my-10', 'text-center']"
      :style="{ maxWidth: '200px', margin: '20px auto'}"
      slot="activator"
    >
      + Ajouter un contrat
    </button>
    <div :class="['container', 'relative']">
      <section :class="['container-header', 'mb-5']">
        <h1 :class="['container-header--title--secondary']">
          <span :class="['bold']">
            + Ajouter un contrat
          </span>
        </h1>
        <hr :class="['absolute', 'w-full']" :style="{ top: '80px', left: '0', right: '0' }" />
      </section>

      <div class="p-10 text-center">
        Si vous souhaitez ajouter un nouveau contrat à votre liste de gestion des documents contractuels, nous vous invitons à cliquer sur le bouton « Créer un contrat » et renseigner l’ensemble des informations relatives au nouveau contrat.
        <br /> Si le document à ajouter est un avenant, vous devez cliquer sur le bouton « Créer un avenant » et sélectionner le « Contrat parent » auquel il doit se rattacher.
      </div>

      <div :class="['flex', 'items-center', 'row', 'justify-center']">
        <div v-for="option in options"
          :key="option.id"
          :class="['py-1', 'px-5', 'flex', 'flex-row', 'items-center']"
        >
          <input
            :key="option.id"
            :value="option.id"
            :id="option.id"
            v-model="typeContract"
            name="contracts"
            type="radio"
            :class="'cursor-pointer'"
          />
          <label :for="option.id" :class="['px-1', 'cursor-pointer']">
            {{ option.label }}
          </label>
        </div>
      </div>

      <div :class="['flex', 'flex-col', 'mx-auto', 'mt-5']" :style="{ width: '50%'}">
        <label
          for="parent-contract"
          :class="['form-label', 'font-bold']"
          v-show="typeContract === 'amendment'"
        >
          Contrat parent
        </label>
        <autosuggest
          v-show="typeContract === 'amendment'"
          :suggestions="[{ data: dropdownContracts }]"
          @select="handlerParentContract"
        />
      </div>

      <div :class="['flex', 'items-center', 'row', 'justify-center', 'my-10']">
        <button
        :class="['btn', 'btn-outline--primary', 'mx-5']"
        @click="handleClose"
      >
        Annuler
      </button>
      <button
        :class="['btn', 'btn-secondary', 'mx-5', isSubmitCUShadowed ? 'disabled': null]"
        :disabled="isSubmitCUShadowed"
        @click="redirectToForm"
      >
        Valider
      </button>
      </div>
    </div>
  </modal>
</template>

<script>
import $axios from "@/axios";
import Modal from '@/components/commons/Modal'
import SelectFilter from '@/components/commons/SelectFilter.vue'
import Autosuggest from "@/components/commons/Autosuggest.vue";

export default {
  components: {
    Modal,
    SelectFilter,
    Autosuggest
  },

  data: () => ({
    isAddModalOpen: false,
    isCUChecked: false,
    typeContract: null,
    dropdownContracts: [],
    parentContract: null,

  }),

  computed: {
    isSubmitCUShadowed () {
      return this.typeContract === 'amendment' && !this.parentContract
    },
    options () {
      return [
        {
          id: 'contract',
          label: 'Créer un contrat'
        },
        {
          id: 'amendment',
          label: 'Créer un avenant'
        },
      ]
    }
  },

  beforeMount(){
    this.getStatusCU()

    this.getResourcesDropdowns()

    this.typeContract = this.options?.find((opt) => opt.id === 'contract')?.id
  },

  methods: {
    /**
     * Close action from parent
     * @return {void}
     */
    handleClose(){
      this.$refs.addContractModal.handleClose();
    },

    /**
     * Handler (update des valeurs du form)
     * @return {void}
     */
     handlerParentContract(value){
      this.parentContract = value
    },

    /**
     * Patch contracts Consent Action
     * @return {void}
     */
    async redirectToForm(){
      if (this.typeContract === 'amendment') {
        await this.$router.push({ name: 'contract-detail-add', params: { id: this.parentContract }})
      } else {
        await this.$router.push({ name: 'contract-detail-add' })
      }
    },

    /**
     * Get contracts Consent Status
     * @return {void}
     */
    async getStatusCU(){
      try {
        const response = await $axios.get(
          `${process.env.VUE_APP_API_VERSION}/account-informations`
        )

        if (response?.data?.contractsConsent){
          this.isAddModalOpen = false
        }
      } catch (err) {
        console.error(err)
      } finally {}
    },

    /**
     * get Resources dropdowns
     * @return {void}
     */
     async getResourcesDropdowns(){
      try {
        const response = await $axios.get(
          `${process.env.VUE_APP_API_VERSION}/contract/autocompletes?types=contracts`
        )

        this.dropdownContracts = Object.keys(response.data.contracts).map((key) => { return response.data.contracts[key]; })
      } catch (error) {}
    },
  }
}
</script>

<style lang="scss">
.modal--addContract_amendment {

  .dialog-window {
    height: -webkit-fill-available !important;
  }
}
</style>
