<template>
    <modal :visible="isCUModalOpen" :close-action="'NULL'" :is-shadow-closable="false">
      <div :class="['container', 'relative']">
        <section :class="['container-header', 'mb-5']">
          <h1 :class="['container-header--title--secondary']">
            <span :class="['bold']">
              Conditions d'utilisation
            </span>
          </h1>
          <hr :class="['absolute', 'w-full']" :style="{ top: '80px', left: '0', right: '0' }" />
        </section>

        <div class="p-3">
          MERCURIAL met à votre disposition une solution de gestion de vos contrats pour vous permettre une centralisation des documents contractuels et faciliter le suivi de vos engagements liés au cycle de vie de vos contrats et leurs éventuels avenants, jusqu’à la date de fin du contrat.
          <br />Selon les droits qui vous sont attribués, vous pourrez accéder aux contrats, ajouter ou supprimer des contrats ou informations ou simplement visualiser la liste des documents.
        </div>

        <div :class="['flex', 'items-center', 'row', 'justify-center']">
          <input
            v-model="isCUChecked"
            class="cursor-pointer"
            type="checkbox"
            :id="'chekbox__cgu'"
          >
          <label for="chekbox__cgu" :style="{ fontWeight: 'bold' }" :class="'mx-2'">
            Je consens à l’utilisation de l’outil de gestion des contrats dans le respect des règles d’accès et de confidentialité.
          </label>
          <div>
            <icon
            icon="infos-primary-bluer"
            size="md"
            :style="{ cursor: 'pointer'}"
            v-tooltip.top="'En donnant son consentement, le collaborateur reconnaît être habilité, selon les droits qui lui sont autorisés, à accéder aux contrats en mode lecture et/ou écriture.'"
          />
          </div>
        </div>
        <button
          :class="['btn', 'btn-primary', 'm-auto', 'w-fit', 'my-10', !isCUChecked ? 'disabled': null]"
          @click="submitCU"
        >
          Valider
        </button>
      </div>
    </modal>
</template>

<script>
import $axios from "@/axios";
import Modal from '@/components/commons/Modal'

export default {
  components: {
    Modal,
  },

  data: () => ({
    isCUModalOpen: false,
    isCUChecked: false,
  }),

  beforeMount(){
    this.getStatusCU()
  },

  methods: {
    /**
     * Patch contracts Consent Action
     * @return {void}
     */
    async submitCU(){
      try {
        const request = await $axios.patch(
          `${process.env.VUE_APP_API_VERSION}/account-informations`,
          {
            contractsConsent: true
          }, {
            headers: {
              'X-CSRF-Token': this.$store.state.authentication.xcsrf_token
            }
          }
        )

        if (request.status === 200){
          this.isCUModalOpen = false
        }
      } catch (err) {
        console.error(err)
      } finally {}
    },
    /**
     * Get contracts Consent Status
     * @return {void}
     */
    async getStatusCU(){
      try {
        const response = await $axios.get(
          `${process.env.VUE_APP_API_VERSION}/account-informations`
        )

        if (!response?.data?.contractsConsent){
          this.isCUModalOpen = true
        }
      } catch (err) {
        console.error(err)
      } finally {}
    }
  }
}
</script>
