<template>
    <modal :visible="isPursuitModalOpen" :close-action="'NULL'" :is-shadow-closable="false">
      <div :class="['container', 'relative']">
        <section :class="['container-header', 'mb-5']">
          <h1 :class="['container-header--title--secondary']">
            <span :class="['bold']">
              Poursuivre le contrat
            </span>
          </h1>
          <hr :class="['absolute', 'w-full']" :style="{ top: '80px', left: '0', right: '0' }" />
        </section>

        <div class="p-10 text-center">
          Votre contrat arrive prochainement à son échéance. S’il se poursuit, nous vous invitons à cliquer sur « Conserver le contrat » et renseigner la durée de reconduction.
          <br />Dans le cas où un avenant a été convenu avec votre fournisseur, nous vous invitons à cliquer sur « Créer un avenant ».
        </div>

      <div :class="['flex', 'items-center', 'row', 'justify-center']">
        <div v-for="option in options"
          :key="option.id"
          :class="['py-1', 'px-5', 'flex', 'flex-row', 'items-center']"
        >
          <input
            :key="option.id"
            :value="option.id"
            :id="option.id"
            v-model="typeContract"
            name="contracts"
            type="radio"
            :class="'cursor-pointer'"
          />
          <label :for="option.id" :class="['px-1', 'cursor-pointer']">
            {{ option.label }}
          </label>
        </div>
      </div>

      <div
        v-show="typeContract === 'contract'"
        :class="['flex', 'flex-col', 'mx-auto', 'mt-5']"
        :style="{ width: '50%'}"
      >
        <label
          for="parent-contract"
          :class="['form-label', 'font-bold']"
        >
          Durée de reconduction en mois
        </label>

        <input
          id="durationMonths"
          name="durationMonths"
          v-model="durationMonths"
          type="number"
          :class="['cursor-pointer', 'form-input']"
        />
      </div>

      <div
        v-show="typeContract === 'amendment'"
        :class="['flex', 'flex-col', 'mx-auto', 'mt-5']"
        :style="{ width: '50%'}"
      >
        <label
          for="parent-contract"
          :class="['form-label', 'font-bold', 'text-center']"
        >
          Contrat parent
        </label>
        <input
          id="parentContract"
          name="parentContract"
          v-model="parentContract"
          type="text"
          :class="[
            'form-input', 'disabled',
          ]"
          :disabled="true"
        />
      </div>

      <div :class="['flex', 'items-center', 'row', 'justify-center', 'my-10']">
        <button
        :class="['btn', 'btn-outline--primary', 'mx-5']"
        @click="handleClose"
      >
        Annuler
      </button>
      <button
        :class="['btn', 'btn-secondary', 'mx-5', isDisabled ? 'disabled' : null]"
        @click="submitPursuitContract"
      >
        Valider
      </button>
      </div>
      </div>
    </modal>
  </template>

  <script>
  import $axios from "@/axios";
  import Modal from '@/components/commons/Modal'

  export default {
    components: {
      Modal,
    },

    props: {
      isPursuitModalOpen: {
        type: Boolean,
        default: false,
      },
      renewalDuration: {
        type: Number | String | null,
        default: null,
      },
      parentContract: {
        type:  String | null,
        default: null,
      },
      id: {
        type: String | Number | null,
        default: null,
      }
    },

    data: () => ({
      typeContract: null,
      dropdownContracts: [],
      durationMonths: null,
    }),

    computed: {
      options () {
        return [
          {
            id: 'contract',
            label: 'Conserver le contrat'
          },
          {
            id: 'amendment',
            label: 'Créer un avenant'
          },
        ]
      },

      isDisabled(){
        return this.typeContract === 'contract' && (!this.durationMonths || this.durationMonths < 1)
      }
    },

    watch: {
      isPursuitModalOpen(newVal, oldVal){
        if (!newVal || newVal === oldVal) return;
      },

      renewalDuration(newVal, oldVal){
        if (!newVal || newVal === oldVal) return;

        this.durationMonths = newVal
      },

      parentContract(newVal, oldVal){
        if (!newVal || newVal === oldVal) return;

        this.parentContract = newVal
      }
    },

    beforeMount(){
      this.getResourcesDropdowns()

      this.typeContract = this.options?.find((opt) => opt.id === 'contract')?.id
    },

    methods: {
      /**
       * Close action from Pursuit
       * @return {void}
       */
      handleClose(){
        this.$emit('closeModalPursuit')
      },

      /**
       * get Resources dropdowns
       * @return {void}
       */
      async getResourcesDropdowns(){
        try {
          const response = await $axios.get(
            `${process.env.VUE_APP_API_VERSION}/contract/autocompletes?types=contracts`
          )

          this.dropdownContracts = Object.keys(response.data.contracts).map((key) => { return response.data.contracts[key]; })
        } catch (error) {}
      },

      /**
       * submit pursuit contract
       * @return {void}
       */
      submitPursuitContract(){
        this.handleClose()
        if(this.typeContract === 'amendment'){
          this.$router.push(
            { name: 'contract-detail-add', params: { id: this.id }}
          )
        } else {
          this.$emit('submit', this.durationMonths)
        }
      }
    }
  }
  </script>
